/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Script from 'next/script';
import { useDegenStore } from '#lib/hooks/useGlobalMode';
import { gsap } from 'gsap';
import DaisyButton from '../../Daisy/DaisyButton';
import cn from 'classnames';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import { FrontPageCarousel, Project } from '#types/index';
import dynamic from 'next/dynamic';
import { getURLForSrc } from '#lib/utils/ImageUtils';
import { useRouter } from 'next/router';
import Truncate from 'react-truncate';
const ReactPlayerWrapper = dynamic(
  () => import('#components/Assets/DSVideoPlayer'),
  {
    ssr: false,
  }
);
function swapInfosClass(
  currentInfoEl: Element,
  previousInfoEl: Element,
  nextInfoEl: Element,
  direction: 'left' | 'right'
) {
  currentInfoEl.classList.remove('current--info');
  previousInfoEl.classList.remove('previous--info');
  nextInfoEl.classList.remove('next--info');

  if (direction === 'right') {
    currentInfoEl.classList.add('previous--info');
    nextInfoEl.classList.add('current--info');
    previousInfoEl.classList.add('next--info');
  } else if (direction === 'left') {
    currentInfoEl.classList.add('next--info');
    nextInfoEl.classList.add('previous--info');
    previousInfoEl.classList.add('current--info');
  }
}

type TheOneProps = {
  heroCarousel?: FrontPageCarousel[];
};

const blendModeApproach = 'mix-blend-difference';

export const TheOne = ({ heroCarousel }: TheOneProps) => {
  const appDivRef = useRef<HTMLDivElement>(null);
  const cardListRef = useRef<HTMLDivElement>(null);
  const cardsWrapperRef = useRef<HTMLDivElement>(null);
  const cardInfoWrapperRef = useRef<HTMLDivElement>(null);
  const appBgContainerRef = useRef<HTMLDivElement>(null);
  const currentCard = useRef<HTMLDivElement>();
  const imgs = useRef<HTMLImageElement[]>([]);

  const [selectedCategory, setSelectedCategory] = useState('Trending');
  const [showCreatorRegion, setShowCreatorRegion] = useState(false);

  const [displaySet, setDisplaySet] = useState<Project[] | undefined>(
    heroCarousel && heroCarousel[0]?.projectItems
  );

  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function resetCardTransforms(cardInfosContainerEl: Element, e: any) {
    const card = e.currentTarget;
    const currentInfoEl = cardInfosContainerEl.querySelector('.current--info');
    gsap.set(card, {
      '--current-card-rotation-offset': 0,
    });
    gsap.set(currentInfoEl, {
      rotateY: 0,
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function updateCard(cardInfosContainerEl: Element, e: any) {
    const card = e.currentTarget;

    if (!card) return;

    const box = card.getBoundingClientRect();
    const centerPosition = {
      x: box.left + box.width / 2,
      y: box.top + box.height / 2,
    };
    const angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
    gsap.set(card, {
      '--current-card-rotation-offset': `${angle}deg`,
    });
    const currentInfoEl = cardInfosContainerEl.querySelector('.current--info');
    gsap.set(currentInfoEl, {
      rotateY: `${angle}deg`,
    });
  }

  function removeCardEvents(card: HTMLDivElement) {
    card.removeEventListener('pointermove', (e) => updateCard(card, e));
  }

  const changeInfo = (direction: 'left' | 'right') => {
    const cardInfosContainerEl = cardInfoWrapperRef.current;

    if (!cardInfosContainerEl) return;

    const currentInfoEl = cardInfosContainerEl.querySelector('.current--info');
    const previousInfoEl =
      cardInfosContainerEl.querySelector('.previous--info');
    const nextInfoEl = cardInfosContainerEl.querySelector('.next--info');

    if (!currentInfoEl || !previousInfoEl || !nextInfoEl) return;

    gsap
      .timeline()
      // .to([buttons.prev, buttons.next], {
      //   duration: 0.2,
      //   opacity: 0.5,
      //   pointerEvents: 'none',
      // })
      .to(
        [currentInfoEl.querySelectorAll('.text'), currentInfoEl],
        {
          duration: 0.5,
          stagger: 0.1,
          translateY: '-120px',
          opacity: 0,
          delay: 1.0,
        },
        '-='
      )
      .call(() => {
        swapInfosClass(currentInfoEl, previousInfoEl, nextInfoEl, direction);
      })
      .call(() => {
        const cardsContainerEl = cardsWrapperRef.current;
        if (!cardsContainerEl) return;
        const currentCardEl = cardsContainerEl.querySelector('.current--card');
        if (!currentCardEl) return;

        currentCardEl.addEventListener('pointermove', (e) =>
          updateCard(cardInfosContainerEl, e)
        );
        currentCardEl.addEventListener('pointerout', (e) => {
          resetCardTransforms(cardInfosContainerEl, e);
        });
      })
      .fromTo(
        direction === 'right'
          ? [nextInfoEl.querySelectorAll('.text'), nextInfoEl]
          : [previousInfoEl.querySelectorAll('.text'), previousInfoEl],
        {
          opacity: 0,
          translateY: '0px',
        },
        {
          duration: 0.4,
          stagger: 0.1,
          translateY: '0px',
          opacity: 1,
        }
      );
    // .to([buttons.prev, buttons.next], {
    //   duration: 0.2,
    //   opacity: 1,
    //   pointerEvents: 'all',
    // });
  };

  const swapCards = (direction: 'left' | 'right') => {
    const cardsContainerEl = cardsWrapperRef.current;
    if (!cardsContainerEl) return;
    const currentCardEl = cardsContainerEl.querySelector(
      '.current--card'
    ) as HTMLDivElement;
    const previousCardEl = cardsContainerEl.querySelector(
      '.previous--card'
    ) as HTMLDivElement;
    const nextCardEl = cardsContainerEl.querySelector(
      '.next--card'
    ) as HTMLDivElement;
    const appBgContainerEl = appBgContainerRef.current;

    if (!appBgContainerEl) return;

    const currentBgImageEl = appBgContainerEl.querySelector(
      '.current--image'
    ) as HTMLDivElement;
    const previousBgImageEl = appBgContainerEl.querySelector(
      '.previous--image'
    ) as HTMLDivElement;
    const nextBgImageEl = appBgContainerEl.querySelector(
      '.next--image'
    ) as HTMLDivElement;

    changeInfo(direction);
    swapCardsClass();

    removeCardEvents(currentCardEl);

    function swapCardsClass() {
      if (!currentCardEl || !previousCardEl || !nextCardEl) return;
      if (!currentBgImageEl || !previousBgImageEl || !nextBgImageEl) return;
      currentCardEl.classList.remove('current--card');
      previousCardEl.classList.remove('previous--card');
      nextCardEl.classList.remove('next--card');

      currentBgImageEl.classList.remove('current--image');
      previousBgImageEl.classList.remove('previous--image');
      nextBgImageEl.classList.remove('next--image');

      currentCardEl.style.zIndex = '50';
      currentBgImageEl.style.zIndex = '-2';

      if (direction === 'right') {
        previousCardEl.style.zIndex = '20';
        nextCardEl.style.zIndex = '30';

        nextBgImageEl.style.zIndex = '-1';

        currentCardEl.classList.add('previous--card');
        previousCardEl.classList.add('next--card');
        nextCardEl.classList.add('current--card');

        currentBgImageEl.classList.add('previous--image');
        previousBgImageEl.classList.add('next--image');
        nextBgImageEl.classList.add('current--image');
        currentCard.current = nextCardEl;
      } else if (direction === 'left') {
        previousCardEl.style.zIndex = '30';
        nextCardEl.style.zIndex = '20';

        previousBgImageEl.style.zIndex = '-1';

        currentCardEl.classList.add('next--card');
        previousCardEl.classList.add('current--card');
        nextCardEl.classList.add('previous--card');

        currentBgImageEl.classList.add('next--image');
        previousBgImageEl.classList.add('current--image');
        nextBgImageEl.classList.add('previous--image');
        currentCard.current = previousCardEl;
      }
    }
  };

  useEffect(() => {
    console.log('GASP', cardsWrapperRef.current);
    const cardsContainerEl = cardsWrapperRef.current;
    const cardInfosContainerEl = cardInfoWrapperRef.current;

    if (!cardsContainerEl) {
      console.log('GASP', 'no cardsWrapperRef');
      return;
    }
    if (!cardInfosContainerEl) {
      console.log('GASP', 'no cardInfosContainerEl');
      return;
    }

    // const totalImages = images.length;
    // const loadedImages = 0;

    const textNodes = cardInfosContainerEl
      ?.querySelector('.current--info')
      ?.querySelectorAll('.text');
    const containerNode = cardInfosContainerEl?.querySelector('.current--info');

    if (!textNodes || !containerNode) {
      console.log('DEV Error : no textNodes');
    }

    gsap.set(cardsContainerEl.children, {
      '--card-translateY-offset': '100vh',
    });
    gsap.set(textNodes!, {
      translateY: '40px',
      opacity: 0,
    });
    gsap.set(containerNode, {
      opacity: 0,
    });

    const tl = gsap.timeline();

    tl.delay(0.5)
      .to(cardsContainerEl.children, {
        delay: 0.15,
        duration: 0.5,
        stagger: {
          ease: 'power4.inOut',
          from: 'end',
          amount: 0.1,
        },
        '--card-translateY-offset': '0%',
      })
      .to(containerNode, {
        delay: 0.2,
        duration: 0.1,
        opacity: 1,
      })
      .to(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        textNodes!,
        {
          duration: 0.4,
          stagger: 0.1,
          opacity: 1,
          translateY: 0,
        }
      );
  }, [selectedCategory]);

  return (
    <div className="app pt-16" ref={appDivRef}>
      <div className="cardList" ref={cardListRef}>
        <div className="cards__wrapper" ref={cardsWrapperRef}>
          <div
            className="card current--card cursor-pointer dark:bg-white/20 bg-white"
            ref={(ref) => {
              if (!ref) return;
              currentCard.current = ref;
            }}
            onClick={(e) => {
              if (e.currentTarget === currentCard.current) {
                !!displaySet &&
                  router.push(`/marketplace/${displaySet[0]?.tokenPrefix}`);
              } else {
                //do the card swap
                e.currentTarget.classList.contains('next--card')
                  ? swapCards('right')
                  : swapCards('left');
              }
            }}
          >
            <div className="card__image">
              {!!displaySet && displaySet[0]?.videoCover && (
                <div className="relative bg-white dark:bg-black w-full flex-none rounded-2xl overflow-hidden blur-none h-full ">
                  <div className="h-full -mt-4">
                    <ReactPlayerWrapper
                      src={displaySet[0]?.videoCover}
                      shouldPlay={true}
                      showControls={false}
                      muted={true}
                    />
                  </div>
                </div>
              )}
              {!!displaySet && !displaySet[0]?.videoCover && (
                <div className="relative  w-full flex-none rounded-2xl overflow-hidden blur-none h-full ">
                  {/* <img
                    src={(!!displaySet && displaySet[0]?.heroImage) || ''}
                    alt=""
                    ref={(ref) => ref && imgs.current.push(ref)}
                  /> */}
                  <Image
                    //    src={getURLForSrc(displaySet[0]?.heroImage, 900)}
                    src={displaySet![0]?.heroImage || ''}
                    alt=""
                    // width={width}
                    // height={height}
                    quality={100}
                    layout="fill"
                    priority={false}
                    placeholder="blur"
                    blurDataURL={displaySet[0]?.heroImageB64 || ''}
                    className={`filter duration-200  `}
                  />
                </div>
              )}
            </div>
            <div className="realtive h-[72px] md:w-20 xl:w-32 w-[72px] md:h-20  xl:h-32 2xl:h-26 rounded-xl md:rounded-2xl p-2 left-4 absolute top-4 overflow-hidden backdrop-brightness-150 flex items-center justify-center ">
              {/* <img
                src={
                  (!!displaySet &&
                    getURLForSrc(displaySet[0]?.featureImage, 400)) ||
                  ''
                }
                alt=""
                className="object-cover w-full h-full rounded-2xl"
                ref={(ref) => ref && imgs.current.push(ref)}
              /> */}
              {displaySet && (
                <Image
                  src={
                    (!!displaySet &&
                      getURLForSrc(displaySet[0]?.featureImage, 400)) ||
                    ''
                  }
                  className="object-cover w-full h-full rounded-2xl"
                  alt=""
                  layout="fill"
                />
              )}
            </div>

            <div className="absolute bottom-6 left-5">
              <div
                className={`text-white text-lg sm:text-2xl 2xl:text-3xl 3xl:text-3xl 4xl:text-4xl 6xl:text-6xl leading-none font-extrabold my-4  pl-4 pr-8 ${blendModeApproach}`}
              >
                {!!displaySet && displaySet[0]?.name}
              </div>
              <div>
                {displaySet && showCreatorRegion == true && (
                  <div className="w-full flex justify-between">
                    <div className="rounded-full p-1 backdrop-brightness-150  backdrop-blur-2xl bg-white/20  flex gap-3 items-center text-gray-800">
                      <img
                        src={getURLForSrc(
                          displaySet[0]?.creator?.profilePhoto,
                          200
                        )}
                        className="rounded-full w-8 h-8 object-cover"
                        alt=""
                      />

                      <div className="font-bold text-xs pr-3">
                        {displaySet[0]?.creator?.displayName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className="card next--card cursor-pointer dark:bg-white/20 bg-white"
            onClick={(e) => {
              if (e.currentTarget === currentCard.current) {
                !!displaySet &&
                  router.push(`/marketplace/${displaySet[1]?.tokenPrefix}`);
              } else {
                //do the card swap
                e.currentTarget.classList.contains('next--card')
                  ? swapCards('right')
                  : swapCards('left');
              }
            }}
          >
            <div className="card__image">
              {!!displaySet && displaySet[1]?.videoCover && (
                <div className="relative bg-white dark:bg-black w-full flex-none rounded-2xl overflow-hidden blur-none h-full ">
                  <div className="h-full -mt-4">
                    <ReactPlayerWrapper
                      src={displaySet[1]?.videoCover}
                      shouldPlay={true}
                      showControls={false}
                      muted={true}
                    />
                  </div>
                </div>
              )}
              {!!displaySet && !displaySet[1]?.videoCover && (
                <div className="relative  w-full flex-none rounded-2xl overflow-hidden blur-none h-full ">
                  {/* <img
                    src={(!!displaySet && displaySet[0]?.heroImage) || ''}
                    alt=""
                    ref={(ref) => ref && imgs.current.push(ref)}
                  /> */}
                  <Image
                    src={getURLForSrc(displaySet[1]?.heroImage, 900)}
                    unoptimized={true}
                    alt=""
                    // width={width}
                    // height={height}
                    quality={100}
                    layout="fill"
                    priority={false}
                    blurDataURL={displaySet[1]?.heroImageB64}
                    placeholder="blur"
                    className={`filter duration-200  `}
                  />
                </div>
              )}
            </div>
            <div className="h-[72px] md:w-20 xl:w-32 w-[72px] md:h-20  xl:h-32 2xl:h-26 rounded-xl md:rounded-2xl p-2 left-4 absolute top-4 overflow-hidden backdrop-brightness-150 flex items-center justify-center ">
              <img
                src={(!!displaySet && displaySet[1]?.featureImage) || ''}
                alt=""
                className="object-cover w-full h-full rounded-2xl"
                ref={(ref) => ref && imgs.current.push(ref)}
              />
            </div>
            <div className="absolute bottom-6 left-5">
              <div
                className={`text-white text-lg sm:text-2xl 2xl:text-3xl 3xl:text-3xl 4xl:text-4xl 6xl:text-6xl leading-none font-extrabold my-4  pl-4 pr-8 ${blendModeApproach}`}
              >
                {!!displaySet && displaySet[1]?.name}
              </div>
              <div>
                {displaySet && showCreatorRegion == true && (
                  <div className="w-full flex justify-between">
                    <div className="rounded-full p-1 backdrop-brightness-150  backdrop-blur-2xl bg-white/20  flex gap-3 items-center text-gray-800">
                      <img
                        src={getURLForSrc(
                          displaySet[1]?.creator?.profilePhoto,
                          200
                        )}
                        className="rounded-full w-8 h-8 object-cover"
                        alt=""
                      />

                      <div className="font-bold text-xs pr-3">
                        {displaySet[1]?.creator?.displayName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex justify-between -mt-24">
              {/* <div className="mt-12">5,000 Items</div>
              <div className="mt-12">5,000 Floor</div> */}
            </div>
          </div>

          <div
            className="card previous--card cursor-pointer dark:bg-white/20 bg-white"
            onClick={(e) => {
              if (e.currentTarget === currentCard.current) {
                !!displaySet &&
                  router.push(`/marketplace/${displaySet[2]?.tokenPrefix}`);
              } else {
                //do the card swap
                e.currentTarget.classList.contains('next--card')
                  ? swapCards('right')
                  : swapCards('left');
              }
            }}
          >
            <div className="card__image">
              {!!displaySet && displaySet[2]?.videoCover && (
                <div className="relative bg-white dark:bg-black w-full flex-none rounded-2xl overflow-hidden blur-none h-full ">
                  <div className="h-full -mt-4">
                    <ReactPlayerWrapper
                      src={displaySet[2]?.videoCover}
                      shouldPlay={true}
                      showControls={false}
                      muted={true}
                    />
                  </div>
                </div>
              )}
              {!!displaySet && !displaySet[2]?.videoCover && (
                <div className="relative  w-full flex-none rounded-2xl overflow-hidden blur-none h-full ">
                  {/* <img
                    src={(!!displaySet && displaySet[0]?.heroImage) || ''}
                    alt=""
                    ref={(ref) => ref && imgs.current.push(ref)}
                  /> */}
                  <Image
                    src={getURLForSrc(displaySet[2]?.heroImage, 900)}
                    unoptimized={true}
                    alt=""
                    // width={width}
                    // height={height}
                    quality={100}
                    layout="fill"
                    priority={false}
                    blurDataURL={displaySet[2]?.heroImageB64}
                    placeholder="blur"
                    className={`filter duration-200  `}
                  />
                </div>
              )}
            </div>
            <div className="h-[72px] md:w-20 xl:w-32 w-[72px] md:h-20  xl:h-32 2xl:h-26 rounded-xl md:rounded-2xl p-2 left-4 absolute top-4 overflow-hidden backdrop-brightness-150 flex items-center justify-center ">
              <img
                src={(!!displaySet && displaySet[2]?.featureImage) || ''}
                alt=""
                className="object-cover w-full h-full rounded-xl md:rounded-2xl"
                ref={(ref) => ref && imgs.current.push(ref)}
              />
            </div>
            <div className="absolute bottom-6 left-5">
              <div
                className={`text-white text-lg md:text-2xl 2xl:text-3xl 3xl:text-3xl 4xl:text-4xl 6xl:text-6xl leading-none font-extrabold my-4  pl-4 pr-8 ${blendModeApproach}`}
              >
                {!!displaySet && displaySet[2]?.name}
              </div>
              <div>
                {displaySet &&
                  !!displaySet[2]?.creator &&
                  showCreatorRegion == true && (
                    <div className="w-full flex justify-between">
                      <div className="rounded-full p-1 backdrop-brightness-150  backdrop-blur-2xl bg-white/20  flex gap-3 items-center text-gray-800">
                        <img
                          src={getURLForSrc(
                            displaySet[2]?.creator?.profilePhoto,
                            200
                          )}
                          className="rounded-full w-8 h-8 object-cover"
                          alt=""
                        />

                        <div className="font-bold text-xs pr-3">
                          {displaySet[2]?.creator?.displayName}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="infoList hidden" ref={cardInfoWrapperRef}>
        <div className="info__wrapper ">
          <div className="info current--info max-w-[40rem] rounded-2xl p-6 ">
            <h1 className="text text-3xl 2xl:text-4xl 4xl:text-6xl font-extrabold text-white px-4 w-full leading-10 mt-4 mb-4 flex items-center text-shadow shadow-white/50">
              {!!displaySet && displaySet[0]?.name}
            </h1>

            <p className="break-all text text-white  px-4 font-light max-h-36 overflow-y-hidden mb-1 w-[30rem]">
              {!!displaySet && displaySet[0]?.projectDescription}
            </p>
          </div>

          <div className="info next--info rounded-2xl max-w-[40rem]  p-6 ">
            <h1 className="text  text-3xl 2xl:text-4xl 4xl:text-6xl font-extrabold dark:text-white text-gray-200 px-4 w-full leading-10 mt-4 mb-4 flex items-center text-shadow shadow-white/50">
              {!!displaySet && displaySet[1]?.name}
            </h1>
            <p className="text break-all dark:text-white text-gray-200 px-4 font-light max-h-36 overflow-y-hidden mb-1 w-[30rem]">
              {!!displaySet && displaySet[1]?.projectDescription}
            </p>
          </div>

          <div className="info previous--info rounded-2xl max-w-[40rem]  p-6 ">
            <h1 className="text text-3xl 2xl:text-4xl 4xl:text-6xl font-extrabold dark:text-white text-gray-200 px-4 w-full leading-10 mt-4 mb-4 flex items-center text-shadow shadow-white/50">
              {!!displaySet && displaySet[2]?.name}
            </h1>
            <p className="text break-all dark:text-white text-gray-200 px-4 font-light max-h-36 overflow-y-hidden mb-1 w-[30rem]">
              {!!displaySet && displaySet[2]?.projectDescription}
            </p>
          </div>
        </div>
      </div>

      <div className="relative app__bg " ref={appBgContainerRef}>
        <div className="app__bg__image current--image rounded-2xl overflow-hidden m-8 bg-white panel">
          <Image
            src={
              //   (!!displaySet && getURLForSrc(displaySet[0]?.bannerImage, 1800)) ||
              displaySet![0]?.bannerImage || ''
            }
            alt=""
            className="img rounded-2xl"
            layout="fill"
            blurDataURL={displaySet && displaySet[0]?.bannerImageB64}
            placeholder={!!displaySet ? 'blur' : 'empty'}
            priority={false}
          />
        </div>
        <div className="app__bg__image next--image">
          <Image
            src={
              (!!displaySet &&
                getURLForSrc(displaySet[1]?.bannerImage, 1800)) ||
              ''
            }
            alt=""
            className="img rounded-2xl"
            layout="fill"
            priority={false}
          />
        </div>
        <div className="app__bg__image previous--image">
          <Image
            src={
              (!!displaySet &&
                getURLForSrc(displaySet[2]?.bannerImage, 1800)) ||
              ''
            }
            alt=""
            className="img rounded-2xl"
            layout="fill"
            priority={false}
          />
        </div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-base-200/20 via-base-200/40 to-base-200 dark:from-base-100/50 dark:via-base-200/30  dark:to-base-200 blur-none z-10"></div>
      <div className=" absolute w-24 z-[20] top-[5rem] h-1/2  left-0 sm:left-8 flex flex-col gap-3 justify-end items-start">
        <span className="-mt-16">
          {heroCarousel &&
            heroCarousel.map((c, idx) => (
              <React.Fragment key={c.category}>
                <DaisyButton
                  label={c.category}
                  size="btn-sm"
                  classNames={cn('font-light', {
                    'text-secondary':
                      c.category == selectedCategory ||
                      (!selectedCategory && idx == 0),
                  })}
                  colorName="btn-ghost"
                  onClick={() => {
                    setSelectedCategory(c.category);
                    setDisplaySet(c.projectItems);
                    setShowCreatorRegion(c.showCreator || false);
                  }}
                />
              </React.Fragment>
            ))}
        </span>
      </div>
      <DaisyButton
        label=""
        icon={<ChevronLeftIcon className="w-4" />}
        onClick={() => swapCards('right')}
        classNames="cardList__btn btn btn--left w-12 p-2 w-12 h-12 rounded-full p-2 bottom-8"
      />
      <DaisyButton
        label=""
        icon={<ChevronRightIcon className="w-4" />}
        onClick={() => swapCards('left')}
        classNames="cardList__btn btn-round btn btn--right w-12 h-12 rounded-full p-2 bottom-8"
      />
    </div>
  );
};
